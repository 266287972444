export const ReplaceHTMLFromString = (value) => {
  const entityMap = {
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&apos;': "'",
    '&amp;': '&',
    '&nbsp;': ' ',
  };

  const entityRegExp = new RegExp(Object.keys(entityMap).join('|'), 'g');
  value = value.replace(entityRegExp, match => entityMap[match]);
  value = value.replace(/<[^>]*>/g, '');
  return value;

}

export const checkColorBrightness = (hexColor) => {
  if (hexColor) {
    hexColor = hexColor.replace(/^#/, '');

    // Parse the hex color to get RGB components
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    // Calculate the brightness
    const brightness = (0.299 * r + 0.587 * g + 0.114 * b);

    // You can adjust this threshold as needed
    // A common threshold for considering a color as light is around 128
    if (brightness > 120)
      return "#000";
    else
      return "#fff";
  } return "#fff";
}

export const DatePresets = [
  { label: 'Last Week', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
  { label: 'Last 30 Days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() }
];
