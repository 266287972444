/* eslint-disable default-case */
import moment from 'moment';
import React, { Component, createRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import { Graph } from '../LineChart';
const { PDFDocument, rgb } = require('pdf-lib');

export default class Cases extends Component {
  constructor(props) {
    super(props);

    const TodayDate = moment();
    this.state = {
      cases: [],
      showBy: 0,
      showByName: "Month",
      filterBy: 0,
      filterYear: TodayDate.year(),
      filterMonth: TodayDate.month(),
      filterQuarter: TodayDate.quarter(),
      reportRange: [new Date(moment().add(-30, "days").format("yyyy-MM-DD")), new Date(moment().format("yyyy-MM-DD"))],
      analysisDateRange: [new Date(moment().add(-30, "days").format("yyyy-MM-DD")), new Date(moment().format("yyyy-MM-DD"))],
      years: [],
      caseTypes: ["All", "Open", "Closed", "Bridged"],
      areaFilterName: "All",
      statuses: [],
      status: 0,
      orderBy: "UsernameDown",
      hideThrobble: false,
      graphXName: "Months",
      graphYName: "Cases"
    }

    this.onGroupByChange = this.onGroupByChange.bind(this);
    this.rendershowByFilter = this.rendershowByFilter.bind(this);
    this.renderGraph = this.renderGraph.bind(this);
    this.targetDivRef = createRef()
  }

  updateDateRange = () => {
    this.setState({ showBy: "SpecificDate" })
  }

  renderGraph(data, type, title, xAxisName, yAxisName) {
    return <div className='my-4 d-flex justify-content-center flex-column align-items-center'>
      <h3 className='text-center'>{title}</h3>
      <Graph data={data} type={type} xAxisName={xAxisName} yAxisName={yAxisName} />
    </div>
  }

  getRanHex = (size) => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return "#" + result.join('');
  }


  sortGraphData(data, showBy, filterBy) {
    data = data.sort((a, b) => new Date(a.dateOpened) - new Date(b.dateOpened))
    switch (filterBy) {
      case 0:
        //console.log(this.state.reportRange[0], this.state.reportRange[1])
        if (this.state.reportRange[1]) {
          const startDate = this.state.reportRange[0];
          const endDate = this.state.reportRange[1];

          //console.log(data.filter(item => console.log(moment(item.dateOpened), startDate, moment(item.dateOpened),endDate)), data)
          data = data.filter(item => new Date(item.dateOpened) >= new Date(startDate) && new Date(item.dateOpened) < new Date(endDate));
          return data.reduce((arr, curr) => {
            let name = this.GetName(showBy, moment(curr.dateOpened));
            let index = arr.findIndex(item => item.x === name);
            if (index < 0)
              arr.push({ x: name, y: 1 });
            else
              arr[index].y++;
            return arr;
          }, []);
        }
      case 1:
        data = data.filter(item => moment(item.dateOpened).year() === moment().year());
        return data.reduce((arr, curr) => {
          let name = this.GetName(showBy, moment(curr.dateOpened));
          let index = arr.findIndex(item => item.x === name);
          if (index === -1) {
            arr.push({ x: name, y: 1, date: new Date(curr.dateOpened), caseNumber: this.zeroPad(curr.caseNumber, 5), xName: "Months", yName: "Cases" });
          } else {
            arr[index].y++;
          }
          return arr;
        }, []);
      case 3:
        data = data.filter(item => moment(item.dateOpened).year() === this.state.filterYear);
        return data.reduce((arr, curr) => {
          let name = this.GetName(showBy, moment(curr.dateOpened));
          let index = arr.findIndex(item => item.x === name)
          if (index === -1) {
            arr.push({ x: name, y: 1, date: new Date(curr.dateOpened) })
          } else {
            arr[index].y++
          }
          return arr
        }, []);
      default:
        data = data.filter(item => moment(item.dateOpened).year() === moment().add(-1, "year").year() || moment(item.dateOpened).year() === moment().year());
        return data.reduce((arr, curr) => {

          let name = this.GetName(showBy, moment(curr.dateOpened));;
          let index = arr.findIndex(item => item.x === name);
          if (index === -1) {
            arr.push({ x: name, y: 1, date: new Date(curr.dateOpened), caseNumber: this.zeroPad(curr.caseNumber, 5) })
          } else {
            arr[index].y++
          }
          return arr
        }, []);
    }
  }

  sortAreaData(data, status) {
    data = data.sort((a, b) => new Date(a.dateOpened) - new Date(b.dateOpened));
    if (status > 0)
      data = data.filter(item => item.statusId === status);
    data = data.map(item => ({
      x: this.zeroPad(item.caseNumber, 5),
      y: this.getCaseAge(item.dateOpened, item.dateClosed)
    }))
    return data;
  }

  sortTableData(data) {
    //console.log(data)
    data = data.reduce((arr, curr) => {
      let index = arr.findIndex(x => x.Username === curr?.latestTask?.fileWithName)
      if (index === -1) {
        arr.push({
          Username: curr?.latestTask?.fileWithName,
          numberOfCases: 1,
          timePeriod: [curr.dateAdded, curr.dateAdded],
          caseCompleted: curr.dateClosed ? 1 : 0,
          avgDays: moment(curr.dateClosed).diff(moment(curr.dateOpened), 'days')
        })
      } else {
        arr[index].numberOfCases++
        if (new Date(arr[index].timePeriod[1]) < new Date(curr.dateOpened)) {
          arr[index].timePeriod[1] = curr.dateOpened
        }
        if (curr.dateClosed) {
          arr[index].caseCompleted++
        }
        arr[index].avgDays = arr[index].avgDays + moment(curr.dateClosed).diff(moment(curr.dateOpened), 'days')
      }
      return arr
    }, []);

    return data;
  }

  getCaseAge(dateOpened, dateClosed) {
    let age = 0;
    try {
      if (dateClosed)
        age = moment(dateClosed).diff(moment(dateOpened), 'days');
      else
        age = moment().diff(moment(dateOpened), 'days');
      return age;
    } catch (e) { console.error("Age Error:", e) }
  }

  componentDidMount() {
    this.loadCases();
    this.loadStatus()
  }

  handleExport = async (graphData, areaData, tableData, caseNumberData, completeCasesData, avgDaysData) => {
    ThrobbleHelper.ToggleThrobble(true, "Exporting Cases Report");
    tableData = tableData.map(i => {
      i.timePeriod = moment(i.timePeriod[0]).format("yyyy/MM/DD") + " - " + moment(i.timePeriod[1]).format("yyyy/MM/DD");
      return i;
    });

    let postData = {
      graphData: JSON.stringify(graphData),
      areaData: JSON.stringify(areaData),
      tableData: JSON.stringify(tableData),
      CaseNumberData: JSON.stringify(caseNumberData),
      CompleteCasesData: JSON.stringify(completeCasesData),
      AvgDaysData: JSON.stringify(avgDaysData),
      filterData: JSON.stringify({
        showByName: this.state.showByName,
        graphXName: this.state.graphXName,
        graphYName: this.state.graphYName,
        areaFilterName: this.state.areaFilterName,
      })
    }

    try {
      const bearer = "Bearer " + localStorage.getItem("token");
      const response = await fetch('api/reports/GenerateCasesReport', {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.item1;
        const MimeType = data.item2;
        const Filename = data.item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        // // Create a URL from the response blob
        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();

        ThrobbleHelper.ToggleThrobble(false);
        showToast("success", "Export Cases Report", "Cases Report was successfully exported");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          showToast("error", "Export Case Report", "There was an error exporting cases report");
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
      showToast("error", "Export Case Report", "There was an error exporting cases report");
    }
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  zeroPad = (num, places) => String(num).padStart(places, '0');

  rendershowByFilter(filterBy) {
    switch (filterBy) {
      case 0:
        return (<>
          <label>Select Date Range</label>
          <DatePicker
            className={'w-100 form-control form-control-sm lh-base date-picker'}
            selectsRange={true}
            placeholderText='Select Dates'
            startDate={this.state.reportRange[0]}
            endDate={this.state.reportRange[1]}
            dateFormat='yyyy-MM-dd'
            onChange={(update) => this.setState({ reportRange: update })}
            isClearable={false}
          />
        </>);
      case 3:
        const years = this.state.cases.reduce((arr, item) => {
          const year = moment(item.dateOpened).year();
          if (arr.findIndex(c => c.value === year) < 0)
            arr.push({ value: year, label: year });

          return arr;
        }, []);

        return (<>
          <label>Select Year</label>
          <Select
            options={years}
            isClearable={false}
            isSearchable={true}
            className='select2-sm'
            value={years.find(c => c.value === this.state.filterYear)}
            onChange={e => this.setState({ filterYear: e.value })}
          />
        </>)
      default:
        return <></>
    }
  }

  GetName(showBy, dateOpened) {

    switch (showBy) {
      case 0:
      case 4:
        return dateOpened.format("MMM");
      case 1:
        return "Quarter " + dateOpened.quarter() + " " + dateOpened.format("yyyy");
      case 2:
        return dateOpened.format("yyyy");
      default:
        return dateOpened.format("DD/MM/yyyy");
    }
  }



  onGroupByChange(e) {
    let graphXName;
    switch (e.value) {
      case 0:
      case 4:
        graphXName = "Months";
        break;
      case 1:
        graphXName = "Quarters";
        break;
      case 2:
        graphXName = "Years";
        break;
      default:
        graphXName = "Days";
        break;
    }
    this.setState({ showBy: e.value, showByName: e.label, graphXName });
  }

  render() {
    const data = this.state.cases.sort((a, b) => new Date(a.dateOpened - b.dateOpened))
    let graphData = this.sortGraphData(data, this.state.showBy, this.state.filterBy).map(item => ({
      ...item,
      y: item.y
    }));
    let areaData = this.sortAreaData(data.filter(item => item.statusId === 4 && new Date(item.dateClosed) >= new Date(this.state.reportRange[0]) && new Date(item.dateClosed) <= new Date(moment(this.state.reportRange[1]).add(1, "days"))), this.state.status);
    //console.log(areaData)

    // let areaData = 

    let tableData = this.sortTableData(data);
    let caseNumberData = tableData.map(item => ({
      x: item.Username ? item.Username : "N/A",
      y: item.numberOfCases,
      color: this.getRanHex(6)
    }))

    let completeCasesData = tableData.map(item => ({
      x: item.Username ? item.Username : "N/A",
      y: item.caseCompleted,
      color: this.getRanHex(6)
    }))

    let avgDaysData = tableData.filter(item => item.avgDays).map(item => ({
      x: item.Username ? item.Username : "N/A",
      y: item.avgDays,
      color: this.getRanHex(6)
    }))

    //console.log(caseNumberData, completeCasesData, avgDaysData, tableData)

    // if (this.state.analysisDateRange[1]) {
    //     areaData = areaData.filter(item => moment(item.dateOpened) >= moment(this.state.analysisDateRange[0]) && moment(item.dateOpened) <= moment(this.state.analysisDateRange[1]))
    // }
    // if (this.state.status) {
    //     areaData = areaData.filter(item => item.statusId === parseInt(this.state.status))
    // }

    const ShowByList = [
      { value: 0, label: "Months" },
      { value: 1, label: "Quarters" },
      { value: 2, label: "Years" },
      { value: 3, label: "Days" },
    ]

    const FilterByList = [
      { value: 0, label: 'Date Range' },
      { value: 1, label: "Current Year" },
      { value: 2, label: "Compare Previous Year" },
      { value: 3, label: "Specific Year" }
    ]




    return (
      <div>
        <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
          <h1 style={{ fontSize: "30px", fontWeight: 100 }}>CASE FILES BY PERIOD, USER & AGE ANALYSIS</h1>
        </div>
        <div className='card my-3'>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <label>Group By</label>
                <Select options={ShowByList} isSearchable={false} isClearable={false} className='select2-sm' value={ShowByList.find(c => c.value === this.state.showBy)} onChange={this.onGroupByChange} />
              </div>
              <div className='col-md-3'>
                <label>Filter By</label>
                <Select options={FilterByList} isSearchable={false} isClearable={false}  className='select2-sm' value={FilterByList.find(c => c.value === this.state.filterBy)} onChange={e => this.setState({ filterBy: parseInt(e.value) })} />
              </div>
              <div className="col-md-3">
                {this.rendershowByFilter(this.state.filterBy)}
              </div>
              <div className='col-md-3 px-3 align-self-end text-end'>
                <Button color='primary' size='sm' onClick={() => this.handleExport(graphData, areaData, tableData, caseNumberData, completeCasesData, avgDaysData)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
              </div>
            </div>
          </div>
        </div>
        <div ref={this.targetDivRef} className='p-3'>
          <div className='card'>
            {this.renderGraph(graphData, "column", "Case Files By " + this.state.showByName + " Period", this.state.graphXName, this.state.graphYName)}
          </div>
          <div className='card my-5'>
            
            {this.renderGraph(areaData, "area", "Days Taken To Close Cases", "Cases", "Age (Days)")}
          </div>

        </div>
        <ThrobbleHelper />
      </div>
    )
  }

  async loadCases() {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Cases Report");
      const bearer = "Bearer " + localStorage.getItem("token");
      const response = await fetch('/api/cases', {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        let body = await response.json();
        //console.log(body)
        this.setState({
          cases: body, years: body.reduce((arr, curr) => {
            const year = new Date(curr.dateOpened).getFullYear()
            if (!arr.includes(year)) {
              arr.push(year)
            }
            return arr
          }, [])
        })

        ThrobbleHelper.ToggleThrobble(false);
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
    }
  }

  async loadStatus() {
    try {
      const bearer = "Bearer " + localStorage.getItem("token");
      const response = await fetch('/api/status', {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        let body = await response.json();

        const statuses = [{ label: "- All Cases -", value: 0 }, ...body.map(item => {
          return { label: item.name, value: item.id }
        })];
        this.setState({ statuses })
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        }
        this.setState({ hideThrobble: true });
      }
    } catch (error) {
      console.error(error);
      this.setState({ hideThrobble: true });

    }
  }
}
