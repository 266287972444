/* eslint-disable no-case-declarations */
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, CardBody, Label } from 'reactstrap';
import { DatePresets } from '../../helpers/global';
import { showToast } from '../../helpers/ToastHelper';



export default function TimeToResolution(props) {


  const [startDate, setStartDate] = useState(new Date(moment().add(-30, "days").format("yyyy-MM-DD")));
  const [endDate, setEndDate] = useState(new Date(moment().format("yyyy-MM-DD")));

  const [statsData, setStatsData] = useState();
  const [caseData, setCaseData] = useState();
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    var bearer = 'Bearer ' + localStorage.getItem("token");
    try {
      const StartDate = moment(startDate).format("yyyy-MM-DD");
      const EndDate = moment(endDate).format("yyyy-MM-DD");
      //const response = await fetch("api/reports/GetOutcomesReport/" + startDate + "/" + endDate, {

      const response = await fetch("/api/reports/GetTimeToResolution/" + StartDate + "/" + EndDate, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        
        console.log(data);
        setStatsData(data.item1);
        let cases = [];
        data.item2.forEach(element => {
          cases.push({
            caseNumber: element.caseNumber,
            complainant: element.complainant,
            respondent: element.respondent,
            dateOpened: moment(element.dateOpened).format("yyyy-MM-DD"),
            dateClosed: element.dateClosed ? moment(element.dateClosed).format("yyyy-MM-DD") : null,
            timeTaken: moment(element.dateClosed).diff(moment(element.dateOpened), 'days') + 1
          });
        });

        //setCaseData(data.item2);
        setCaseData(cases);
        //console.log(data.item2);
        setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const ExportReport = async (evt) => {
    evt.stopPropagation();
    setLoading(true);
    //ThrobbleHelper.ToggleThrobble(true, "Exporting Levels Report");
    try {
      const reportStartDate = moment(startDate).format("yyyy-MM-DD");
      const reportEndDate = moment(endDate).format("yyyy-MM-DD");
      const response = await fetch("api/reports/GenerateExcelTimeToResolution/" + reportStartDate + "/" + reportEndDate, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/pdf"
        }
      })

      if (response.ok) {
        const data = await response.blob();
        const contentDisposition = response.headers.get("content-disposition");
        const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
        const fileName = fileNameMatch
          ? fileNameMatch[1].replace(/"/g, "")
          : "unknown";

        // Create a URL from the response blob
        const fileUrl = URL.createObjectURL(data);
        const LinkBtn = document.createElement("a");
        LinkBtn.download = fileName;
        LinkBtn.href = fileUrl;
        LinkBtn.click();

        //ThrobbleHelper.ToggleThrobble(false);
        setLoading(false);
        showToast("success", "Report Export", "The report was successfully exported");
      } else {
        if (response.status === 401) {
          window.location.href = "/";
        } else {
          //ThrobbleHelper.ToggleThrobble(false);
          setLoading(false);
          showToast("error", "Report Export", "There was an error exporting the report");
        }
      }
    }
    catch (e) {
      console.log(e);
      setLoading(false);
      showToast("error", "Report Export", "There was an error exporting the report");
    }
  }



  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [endDate]);

  const header = () => {
    return (<div className='dms-header d-flex justify-content-center p-3 align-items-center '>
      <h1 style={{ fontSize: "30px", fontWeight: 100 }}>AVERAGE TIME TO RESOLUTION</h1>
    </div>) }

  const padNumber = (number) => {
    return number.toString().padStart(5, '0');
  };

  const updateFilter = (update) => {
    setStartDate(update.startDate);
    setEndDate(update.endDate);
    //loadData();
  }

  if (loading) {
    return (
      <>
        {header()}
        
        <div className="loading" ><i className='fas fa-spinner fa-spin me-2'></i><br />Loading report...</div>
      </>
    )
  }

  return (
    <>
      { header() }

      <Card className='my-3'>
        <CardBody>
          <div className="row">
            <div className="col-md-3">
              <Label>Filter By Date Range</Label><br />
              <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' startDate={startDate} endDate={endDate} presets={DatePresets} format='yyyy-MM-dd' change={updateFilter} separator='to' />
            </div>
            <div className="col-md-9 align-self-end text-end">
              {/*<Button color='primary' onClick={this.ExportReport}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>*/}
              <Button color='primary' onClick={ExportReport}><i className='far fa-file-excel me-2'></i>Export Report</Button>
            </div>
          </div>
        </CardBody>
      </Card>


      <div className="row mb-4">
        <div className="col-md-4 mt-4">
          <div className="card  text-center">
            <div className='card-body'>Resolved Cases</div>
            <div className='card-footer counter'>{statsData?.resolvedCases}</div>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="card  text-center">
            <div className='card-body'>Average Time to Resolution</div>
            <div className='card-footer counter'>{statsData?.timeToResolutionDays + 1} Days</div>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="card  text-center">
            <div className='card-body'>Unresolved Cases</div>
            <div className='card-footer counter'>{statsData?.unresolvedCases}</div>
          </div>
        </div>
        
      </div>

      <GridComponent dataSource={caseData.filter(item => item.dateClosed !== null)} allowSorting={true} toolbar={['Search']} >
        <ColumnsDirective>
          <ColumnDirective field='caseNumber' width='30' headerText="File #" template={(props) => padNumber(props.caseNumber)} />
          <ColumnDirective field='complainant' width='90' headerText="Complainant" />
          <ColumnDirective field='respondent' width='90' headerText="Respondent" />
          <ColumnDirective field='dateOpened' width='50' headerText="Date Opened" type="date" format='y/MM/dd' textAlign='center' />
          <ColumnDirective field='dateClosed' width='50' headerText="Date Resolved" type="date" format='y/MM/dd' textAlign='center' />
          <ColumnDirective field='timeTaken' width='70' headerText="Time Taken (Days)" textAlign='center'  />
          {/*<ColumnDirective headerText='Actions' width='25' commands={CommandTemplate} />*/}
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>


    </>
  );
}

