/* eslint-disable no-case-declarations */
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, ExcelExport, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, CardBody, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Graph } from '../LineChart';
import { DatePresets } from '../../helpers/global';
import { showToast } from '../../helpers/ToastHelper';

export default function NumMattersPerClause(props) {

  const [caseData, setCaseData] = useState();
  const [gridData, setGridData] = useState();
  const [clauseData, setClauseData] = useState();
  const [reportData, setReportData] = useState();
  const [startDate, setStartDate] = useState(new Date(moment().add(-30, "days").format("yyyy-MM-DD")));
  const [endDate, setEndDate] = useState(new Date(moment().format("yyyy-MM-DD")));
  
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [exportName, setExportName] = useState("");
  
  const toggle = () => setEditModal(!editModal);


  const commandClick = async (args) => {
    switch (args.commandColumn.type) {
      default: //view
        const data = caseData.filter(item => {
          return item.clauseId === args.rowData.id;
        });
        //console.log(data);
        setGridData(data);
        setEditModal(true);
        setExportName(args.rowData.x);
        break;

    }
  }

  const commandTemplate = [
    { type: 'View Case Files', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } }
  ];

  const loadData = async () => {
    var bearer = 'Bearer ' + localStorage.getItem("token");
    const reportStartDate = moment(startDate).format("yyyy-MM-DD");
    const reportEndDate = moment(endDate).format("yyyy-MM-DD");
    try {
      const response = await fetch("/api/reports/GetMattersPerClauseReport/" + reportStartDate + "/" + reportEndDate, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setClauseData(data.item1);
        setCaseData(data.item2);
        setReportData(data.item3);
        setLoading(false);
        //setEditData(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const ExportReport = async (evt) => {
    evt.stopPropagation();
    setLoading(true);
    //ThrobbleHelper.ToggleThrobble(true, "Exporting Levels Report");
    try {
      const reportStartDate = moment(startDate).format("yyyy-MM-DD");
      const reportEndDate = moment(endDate).format("yyyy-MM-DD");
      const response = await fetch("api/reports/GenerateExcelMattersPerClauseReport/" + reportStartDate + "/" + reportEndDate, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/pdf"
        }
      })

      if (response.ok) {
        const data = await response.blob();
        const contentDisposition = response.headers.get("content-disposition");
        const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
        const fileName = fileNameMatch
          ? fileNameMatch[1].replace(/"/g, "")
          : "unknown";

        // Create a URL from the response blob
        const fileUrl = URL.createObjectURL(data);
        const LinkBtn = document.createElement("a");
        LinkBtn.download = fileName;
        LinkBtn.href = fileUrl;
        LinkBtn.click();

        //ThrobbleHelper.ToggleThrobble(false);
        setLoading(false);
        showToast("success", "Report Export", "The report was successfully exported");
      } else {
        if (response.status === 401) {
          window.location.href = "/";
        } else {
          //ThrobbleHelper.ToggleThrobble(false);
          setLoading(false);
          showToast("error", "Report Export", "There was an error exporting the report");
        }
      }
    }
    catch (e) {
      console.log(e);
      setLoading(false);
      showToast("error", "Report Export", "There was an error exporting the report");
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [endDate]);

  const header = () => {
    return (<div className='dms-header d-flex justify-content-center p-3 align-items-center mb-4'>
      <h1 style={{ fontSize: "30px", fontWeight: 100 }}>Number of Matters Assigned Per Clause</h1>
    </div>)
  }

  if (loading) {
    return (
      <>
        {header()}
        <div className="loading" ><i className='fas fa-spinner fa-spin me-2'></i><br />Loading report...</div>
      </>
    )
  }

  const padNumber = (number) => {
    return number.toString().padStart(5, '0');
  };

  const updateFilter = (update) => {
    setStartDate(update.startDate);
    setEndDate(update.endDate);
  }

  const excelQueryCellInfo = (args) => {

    if (args.column.field === 'caseNumber') {
      args.value = padNumber(args.data['caseNumber'])
    }
    if (args.column.field === 'dateOpened') {
      args.value = moment(args.data['dateOpened']).format("yyyy-MM-DD");
    }
  }

  const exportReport = (grid, headerText) => {
    const excelExportColumns = [
      { field: 'caseNumber', headerText: "File #", type: 'text', width: 90 },
      { field: 'complainant', headerText: 'Complainant Name', width: 240 },
      { field: 'respondent', headerText: 'Respondent Name', width: 240 },
      { field: 'dateOpened', textAlign: 'Center', headerText: "Date Opened", width: 120 },
      { field: 'outcomeName', headerText: "Outcome", width: 300 },

    ];

    const excelExportProperties = {
      fileName: headerText + '.xlsx',
      columns: excelExportColumns,
      header: {
        headerRows: 1,
        rows: [
          { cells: [{ colSpan: 5, value: headerText, style: { fontColor: '#201d39', fontSize: 16, hAlign: 'Center', bold: true, } }] },
        ]
      }
    };
    grid.excelExport(excelExportProperties);
  }

  let grid;

  return (
    <>
      {header()}
      <Card className='my-3'>
        <CardBody>
          <div className="row">
            <div className="col-md-3">
              <Label>Filter By Date Range</Label><br />
              <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' startDate={startDate} endDate={endDate} presets={DatePresets} format='yyyy-MM-dd' change={updateFilter} separator='to' />
            </div>

            <div className="col-md-9 align-self-end text-end">
              <Button color='primary' onClick={ExportReport}><i className='far fa-file-excel me-2'></i>Export Report</Button>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className='d-flex justify-content-center'>
        <Graph data={reportData.sort((a, b) => b.y - a.y).slice(0, 10)} type={"pie"} />
      </div>

      <div className="mt-5">
        <GridComponent dataSource={reportData} allowSorting={true} toolbar={['Search']} commandClick={commandClick} >
          <ColumnsDirective>
            <ColumnDirective field='x' width='100' headerText="Clause" />
            <ColumnDirective field='y' width='20' headerText="Number of cases" textAlign="center" />
            <ColumnDirective headerText='Actions' width='20' commands={commandTemplate} />
          </ColumnsDirective>
          <Inject services={[Sort, CommandColumn, Toolbar]} />
        </GridComponent>
      </div>

      <Modal isOpen={editModal} toggle={toggle} scrollable size="xl" backdrop="static">
        <ModalHeader toggle={toggle}>Case Files - Clause - {exportName}</ModalHeader>
        <ModalBody>
          <GridComponent id='Grid' ref={g => grid = g} dataSource={gridData} allowSorting={true} allowExcelExport={true} excelQueryCellInfo={excelQueryCellInfo} >
            <ColumnsDirective>
              <ColumnDirective field='caseNumber' width='40' headerText="File #" template={(props) => padNumber(props.caseNumber)} />
              <ColumnDirective field='complainant' width='100' headerText="Complainant Name" />
              <ColumnDirective field='respondent' width='100' headerText="Respondent Name" />
              <ColumnDirective field='dateOpened' width='50' headerText="Date Opened" type="date" format='y/MM/dd' textAlign='center' />
              <ColumnDirective field='outcomeName' width='100' headerText="Outcome" />
            </ColumnsDirective>
            <Inject services={[Sort, ExcelExport]} />
          </GridComponent>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="sm" onClick={() => { if (grid) exportReport(grid, "Complaints By Outcome - " + exportName); }}>Export <i className="far fa-file-excel ms-2"></i></Button>
          <Button color="dark" size="sm" onClick={toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

    </>
  );
}

