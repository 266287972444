/* eslint-disable array-callback-return */
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Row, Label } from 'reactstrap';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import parse from 'html-react-parser';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Inject, ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';



export default class CaseAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      loading: true,
      respondents: [],
      industries: [],
      respondent: "",
      industry: "",
      media: "",
      orderBy: "FileDown",
      reportRange: [new Date(moment().add(-30, "days").format("yyyy-MM-DD")), new Date(moment().format("yyyy-MM-DD"))],
      hideThrobble: false
    }

    this.filterByDateRange = this.filterByDateRange.bind(this);
    this.ExportReport = this.ExportReport.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
  }

  componentDidMount() {
    this.loadCases();
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  zeroPad = (num, places) => String(num).padStart(places, '0');

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  async ExportReport(evt) {
    evt.stopPropagation();
    ThrobbleHelper.ToggleThrobble(true, "Exporting Case Analysis Report");
    try {
      const bearer = "Bearer " + localStorage.getItem("token");
      let data = this.state.cases.map(item => ({ ...item, Age: Math.abs(item.Age) }));

      if (this.state.respondent !== "") {
        data = data.filter(item => this.state.respondent === item.Respondent);

        if (this.state.industry)
          data = data.filter(item => this.state.industry === item.Industry);

        if (this.state.media)
          data = data.filter(item => item.Media.find(c => c.label === this.state.media) != null);
      }
      console.log(data)
      const response = await fetch("api/reports/GenerateAnalysisReport", {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.item1;
        const MimeType = data.item2;
        const Filename = data.item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        // // Create a URL from the response blob
        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();
        ThrobbleHelper.ToggleThrobble(false);
        showToast("success", "Report Export ", "Case analysis report was successfully exported");

      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          showToast("error", "Report Export", "There was an error exporting case analysis report");
        };
      }
    } catch (e) {
      console.error(e);
      ThrobbleHelper.ToggleThrobble(false);
      showToast("error", "Report Export", "There was an error exporting case analysis report");
    }
  }

  onDropdownChange(Type, Value) {
    switch (Type) {
      case "Respondent":
        this.setState({ respondent: Value === "Select Respondent" ? "" : Value, industry: "", media: "" });
        break;
      case "Industry":
        this.setState({ industry: Value });
        break;
      default:
        this.setState({ media: Value });
        break;
    }
  }

  renderDropdown(Data, ShowOption, Type, Value) {

    let DropdownData = [];
    let isDisabled = false;

    switch (Type) {
      case "Respondent":
        DropdownData = [{ value: "", label: "- All -" }, ...Data.map(item => {
          return { value: item.respondentName, label: item.respondentName };
        })];
        break;
      case "Industry":
        if (ShowOption) {
          //console.log(Data.find(c => c.respondentName === this.state.respondent).industries);
          DropdownData = [{ value: "", label: "- All -" }, ...Data.find(c => c.respondentName === this.state.respondent).industries?.map(item => {
            //if (item > 0)
              return { value: item, label: this.state.industries.find(i => i.id === item)?.name };
            //else return;
          })];
        } else
          isDisabled = true;
        break;
      default:
        if (ShowOption) {
          DropdownData = [{ value: "", label: "- All -" }, ...Data.find(c => c.respondentName === this.state.respondent).media?.map(item => {
            return { value: item.label, label: item.label };
          })];
        } else
          isDisabled = true;

    }

    return (
      ShowOption ?
        <Select className='select2-sm' options={DropdownData} isSearchable={true} isClearable={false} backspaceRemovesValue={false} isRtl={false} value={DropdownData.filter(option => option.value === Value)} onChange={e => this.onDropdownChange(Type, e.value)} />
        :
        <select className='form-select form-select-sm' disabled={isDisabled} value={0}>
          <option> First Select Respondent </option>
        </select>
    )
  }

  filterByDateRange(i) {
    if (this.state.reportRange[1]) {
      const startDate = moment(this.state.reportRange[0]).format("yyyy-MM-DD");
      const endDate = moment(this.state.reportRange[1]).format("yyyy-MM-DD");
      const DateOpened = moment(i.DateOpened).format("yyyy-MM-DD");
      return DateOpened >= startDate && DateOpened < endDate;
    }
  }

  updateDateFilter = (update) => {
    let reportDate = [];
    reportDate.push(update.startDate);
    reportDate.push(update.endDate);
    //this.setState({ ReportStartDate: update.startDate, ReportEndDate: update.endDate }, () => this.state.ReportEndDate && this.loadData())
    //this.setState({ reportRange: reportDate }, () => this.state.reportRange && this.loadData())
    this.setState({ reportRange: reportDate });
  }

  padNumber = (number) => {
    return number?.toString().padStart(5, '0');
  };

  render() {

    const presets = [

      { label: 'Last Week', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
      { label: 'Last 30 Days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() }
    ];

    let data = this.state.cases.map(item => ({ ...item, Age: Math.abs(item.Age) }));
    console.log(data)
    data = data.filter(this.filterByDateRange);
    if (this.state.respondent !== "") {
      data = data.filter(item => this.state.respondent === item.Respondent);
      if (this.state.industry)
        data = data.filter(item => this.state.industry === item.Industry);
      if (this.state.media) {
        data = data.filter(item => item.Media.find(c => c.label === this.state.media) != null);
      }
    }

    return (
      <div>
        <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
          <h1 style={{ fontSize: "30px", fontWeight: 100 }}>CASE ANALYSIS</h1>
        </div>

        <Card className='mt-3'>
          <CardBody>
            <Row>
              <Col md={3}>
                <Label>Respondent</Label>
                {this.renderDropdown(this.state.respondents, true, "Respondent", this.state.respondent)}
              </Col>
              <Col md={3}>
                <Label>Industry</Label>
                {this.renderDropdown(this.state.respondents, this.state.respondent !== "", "Industry", this.state.industry)}
              </Col>
              <Col md={3}>
                <Label>Media</Label>
                {this.renderDropdown(this.state.respondents, this.state.respondent !== "", "Media", this.state.media)}
              </Col>
              <Col md={3}>
                <Label>Date Range</Label>
                <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' separator='to' startDate={this.state.reportRange[0]} endDate={this.state.reportRange[1]} presets={presets} format='yyyy-MM-dd' change={this.updateDateFilter} />

             
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button color='primary' onClick={this.ExportReport}><i className='far fa-file-excel me-2'></i> Export Report</Button>
            </div>
          </CardBody>
        </Card>
        <div className='table-container my-5' >
          <GridComponent dataSource={data} allowSorting={true}  >
            <ColumnsDirective>
              <ColumnDirective field='caseNumber' width='30' headerText="File #" />
              <ColumnDirective field='Description' width='100' headerText="Description" disableHtmlEncode={false } />
              <ColumnDirective field='DateOpened' width='50' headerText="Date Opened" type="date" format='y/MM/dd' textAlign='center' />
              <ColumnDirective field='Age' width='40' headerText="Age (Days)" />
              <ColumnDirective field='DateAssigned' width='50' headerText="Assigned Date" type="date" format='y/MM/dd' textAlign='center' />
              
            </ColumnsDirective>
            <Inject services={[Sort, Toolbar]} />
          </GridComponent>
         
        </div>
        <ThrobbleHelper />
      </div>
    )
  }

  async loadIndustries() {

    var bearer = 'Bearer ' + localStorage.getItem("token");
    try {
      const response = await fetch('/api/industry', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        this.setState({ industries: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        //if (response.status === 401)
        //  window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }


  async loadCases() {
    try {

      ThrobbleHelper.ToggleThrobble(true, "Loading Case Analysis Report")
      const response = await fetch('api/cases', {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      })

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        const respondents = data.reduce((arr, curr) => {
          let index = arr.findIndex(item => item.respondentName === curr.respondent)
          if (index === -1) {
            arr.push({
              respondentName: curr.respondent,
              industries: [curr.industry],
              industrieNames: [curr.industryName],
              media: [...JSON.parse(curr.media)]
            })
          } else {

            if (!arr[index].industries.includes(curr.industry))
              arr[index].industries.push(curr.industry);

            const currMedia = JSON.parse(curr.media);
            currMedia.map(item => {
              if (arr[index].media.findIndex(c => c.value === item.value) < 0)
                arr[index].media.push(item);
            })
          }
          return arr
        }, [])

        const today = moment();
        const cases = data.map(item => {

          return {
            //CaseNumber: this.zeroPad(item.caseNumber, 5), Description: item.description, DateOpened: moment(item.dateOpened).format("yyyy-MM-DD"), Age: (new Date().getDate() - new Date(item.dateOpened).getDate()), DateAssigned: moment(item.dateAssigned).format("yyyy-MM-DD"), Respondent: item.respondent, Industry: item.industry, Media: (item.media ? JSON.parse(item.media) : [])
            CaseNumber: this.zeroPad(item.caseNumber, 5), Description: item.description, DateOpened: moment(item.dateOpened).format("yyyy-MM-DD"), Age: today.diff(moment(item.dateOpened), 'days'), DateAssigned: moment(item.dateAssigned).format("yyyy-MM-DD"), Respondent: item.respondent, Industry: item.industry, Media: (item.media ? JSON.parse(item.media) : [])
          }
        });
        this.setState({ cases, respondents });
        this.loadIndustries();
        ThrobbleHelper.ToggleThrobble(false);
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else
          ThrobbleHelper.ToggleThrobble(false);
      }

     
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
    }
  }

}