import React, { Component } from 'react';

class ThrobbleHelper extends Component {

  static parentObj = null;
  constructor(props) {
    super(props);
    ThrobbleHelper.parentObj = this;

    this.state = {
      ShowThrobble: false,
      ThrobbleMessage: ""
    }
  }

  static ToggleThrobble(ShowThrobble, ThrobbleMessage) {
    if (ShowThrobble) {
      if (!ThrobbleHelper.parentObj.state.ShowThrobble)
        ThrobbleHelper.parentObj.setState({ ShowThrobble, ThrobbleMessage });
    }
    else {
      if (ThrobbleMessage)
        setTimeout(() => ThrobbleHelper.parentObj.setState({ ShowThrobble, ThrobbleMessage: "" }), 500);
      else
        ThrobbleHelper.parentObj.setState({ ShowThrobble, ThrobbleMessage: "" })
    }
  }

  render() {

    return (
      <div className="loading" hidden={!this.state.ShowThrobble}><i className='fas fa-spinner fa-spin me-2'></i><br />{this.state.ThrobbleMessage}</div>

    );
  }
}

export default ThrobbleHelper;