export let urls = {
  adminLevel: "/admin/level",
  adminOutcomes: "/admin/outcomes",
  adminConsumer: "/admin/consumer",
  clauses: "/admin/clauses",
  tasks: '/admin/tasks',
  status: "/admin/status",
  users: "/admin/users",
  industries: "/admin/industry",
  medias: "/admin/media",
  membershipStatuses: "/admin/membership-status",
  cases: "/report/cases",
  complainants: "/report/complainants",
  respondents: "/report/respondents",
  attachments: "/report/attachments",
  timeToResolution: "/report/timetoresolution",
  mattersPerClause: "/report/mattersperclause",
  filesPerClause: "/report/filesPerClause",
  media: "/report/media",
  industry: "/report/industry",
  level: "/report/level",
  outcomes: "/report/outcomes",
  caseAnalysis: "/report/case-analysis",
  myDms: "/mydms",
  newCase: "/new-case",
  viewAllCases: "/view-all-cases",
  notePad: "/notepad",
  calendar: "/calendar",
  search: "/search",
  viewClosedCases: "/view-closed-cases",
  viewCaseStatistics: "/view-case-statistics",
  viewMyCases: "/view-my-cases",
  caseLoad: "/case-load",
  logout: "/",
  resetPassword: "/reset-password/:code"
}