/* eslint-disable no-case-declarations */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar, Group } from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';
import { Button, Card, CardBody, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import { DatePresets } from '../../helpers/global';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { showToast } from '../../helpers/ToastHelper';

export default function FilesByClause(props) {

  const [clauseData, setClauseData] = useState();
  const [reportData, setReportData] = useState();
  const [caseData, setCaseData] = useState();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(moment().add(-30, "days").format("yyyy-MM-DD")));
  const [endDate, setEndDate] = useState(new Date(moment().format("yyyy-MM-DD")));
  const [clauseFilterId, setClauseFilterId] = useState();
  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);


  const commandClick = async (args) => {
    switch (args.commandColumn.type) {
      default: //edit
        editItem(args.rowData.id);
        break;
    }
  }

  const loadData = async () => {
    var bearer = 'Bearer ' + localStorage.getItem("token");
    const reportStartDate = moment(startDate).format("yyyy-MM-DD");
    const reportEndDate = moment(endDate).format("yyyy-MM-DD");
    try {
      const response = await fetch("/api/reports/getcaseswithclauses/" + reportStartDate + "/" + reportEndDate, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setReportData(data.item2);
        setClauseData(data.item1);
        setCaseData(data.item2);
        setLoading(false);
        //setEditData(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }


  const ExportReport = async (evt) => {
    evt.stopPropagation();
    setLoading(true);
    //ThrobbleHelper.ToggleThrobble(true, "Exporting Levels Report");
    try {
      const reportStartDate = moment(startDate).format("yyyy-MM-DD");
      const reportEndDate = moment(endDate).format("yyyy-MM-DD");
      const response = await fetch("api/reports/GenerateExcelCasesWithClauses/" + reportStartDate + "/" + reportEndDate, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/pdf"
        }
      })

      if (response.ok) {
        const data = await response.blob();
        const contentDisposition = response.headers.get("content-disposition");
        const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
        const fileName = fileNameMatch
          ? fileNameMatch[1].replace(/"/g, "")
          : "unknown";

        // Create a URL from the response blob
        const fileUrl = URL.createObjectURL(data);
        const LinkBtn = document.createElement("a");
        LinkBtn.download = fileName;
        LinkBtn.href = fileUrl;
        LinkBtn.click();

        //ThrobbleHelper.ToggleThrobble(false);
        setLoading(false);
        showToast("success", "Report Export", "The report was successfully exported");
      } else {
        if (response.status === 401) {
          window.location.href = "/";
        } else {
          //ThrobbleHelper.ToggleThrobble(false);
          setLoading(false);
          showToast("error", "Report Export", "There was an error exporting the report");
        }
      }
    }
    catch (e) {
      console.log(e);
      setLoading(false);
      showToast("error", "Report Export", "There was an error exporting the report");
    }
  }

  const editItem = (id) => {
    setEditModal(true);
  };

 
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [endDate]);

  useEffect(() => {
    //loadData();
    if (clauseFilterId) {
      const filteredData = caseData.filter(item => {
        return item.clauseId === clauseFilterId;
      });
      setReportData(filteredData);
    }
    else
      setReportData(caseData);

  }, [clauseFilterId]);


  const groupOptions = {
    columns: ['clauseCombinedName'],
    showDropArea: false,
    captionTemplate: '<span class="groupItems"> ${headerText} - ${key} : ${count} Files </span>'
  };

  const header = () => {
    return (<div className='dms-header d-flex justify-content-center p-3 align-items-center mb-4'>
      <h1 style={{ fontSize: "30px", fontWeight: 100 }}>FILES PER CLAUSE</h1>
    </div>)
  }

  if (loading) {
    return (
      <>
        {header()}

        <div className="loading" ><i className='fas fa-spinner fa-spin me-2'></i><br />Loading report...</div>
      </>
    )
  }

  const padNumber = (number) => {
    return number.toString().padStart(5, '0');
  };

  const updateFilter = (update) => {
    setStartDate(update.startDate);
    setEndDate(update.endDate);
  }

  
  return (
    <>
      {header()}

      <Card className='my-3'>
        <CardBody>
          <div className="row">
            <div className="col-md-3">
              <Label>Filter By Date Range</Label><br />
              <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' startDate={startDate} endDate={endDate} presets={DatePresets} format='yyyy-MM-dd' change={updateFilter} separator='to' />
            </div>
            <div className="col-md-6">
              <Label>Filter By Clause</Label><br />
              <DropDownListComponent id="ddPotentialRiskUsed" dataSource={clauseData} fields={{ text: 'displayName', value: 'id' }} placeholder='- All Clauses -' floatLabelType='Never' showClearButton={true} value={clauseFilterId} onChange={e => setClauseFilterId(e.value)} />

            </div>

            <div className="col-md-3 align-self-end text-end">
              <Button color='primary' onClick={ExportReport}><i className='far fa-file-excel me-2'></i>Export Report</Button>
            </div>
          </div>
        </CardBody>
      </Card>

      <GridComponent dataSource={reportData} allowSorting={true} allowGrouping={true} groupSettings={groupOptions} toolbar={['Search']} commandClick={commandClick} >
        <ColumnsDirective>
          <ColumnDirective field='caseNumber' width='30' headerText="File #" template={(props) => padNumber(props.caseNumber)} />
          <ColumnDirective width='30' field='clauseCombinedName' headerText="Clause" />
          
          <ColumnDirective field='complainant' width='90' headerText="Complainant" />
          <ColumnDirective field='respondent' width='90' headerText="Respondent" />
          <ColumnDirective field='outcomeName' width='90' headerText="Outcome" />

          {/*<ColumnDirective field='Name' width='100' headerText="Name" />*/}
          {/*<ColumnDirective headerText='Actions' width='25' commands={CommandTemplate} />*/}
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar, Group]} />
      </GridComponent>

      <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Edit Industry</ModalHeader>
        <ModalBody>

        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

    </>
  );
}

