/* eslint-disable default-case */
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, AreaChart, Area, Pie, PieChart, Cell, ResponsiveContainer, Sector } from 'recharts';
import { AreaSeries, Category, ChartComponent, ColumnSeries, BarSeries, DataLabel, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import { Cell, Legend as PieLegend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { GetColor } from '../helpers/Lookup';

export function Graph({ data, type, value, chartName, xAxisName, yAxisName }) {
  let primaryxAxis = null;
  let primaryyAxis = null;

  const width = window.innerWidth / 2
  const TitleStyle = { size: '16px', color: '#0366d6', fontFamily: 'Arial' };
  const LabelStyle = { size: '12px', color: '#000000', fontFamily: 'Arial' };

  switch (type) {
    case "line":

      primaryxAxis = { valueType: 'Category', title: xAxisName, titleStyle: TitleStyle, labelStyle: LabelStyle };
      primaryyAxis = { minimum: 0, title: yAxisName, titleStyle: TitleStyle, labelStyle: LabelStyle };
      return (
        <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
          <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]} />
          <SeriesCollectionDirective>
            <SeriesDirective dataSource={data} xName='x' yName='y' type='Line' />
          </SeriesCollectionDirective>

        </ChartComponent>
      );
    case "column":
      //console.log(yAxisName, xAxisName)
      primaryxAxis = { valueType: 'Category', title: xAxisName, edgeLabelPlacement: 'Shift', titleStyle: TitleStyle, labelStyle: LabelStyle };
      primaryyAxis = { minimum: 0, title: yAxisName, titleStyle: TitleStyle, labelStyle: LabelStyle };
      return (
        <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
          <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]} />
          <SeriesCollectionDirective>
            <SeriesDirective dataSource={data} xName='x' yName='y' type='Column' dataLabelTemplate={<i class="fas fa-star"></i>} />
          </SeriesCollectionDirective>

        </ChartComponent>
      )
    case "bar":
      //console.log(yAxisName, xAxisName)
      primaryxAxis = { valueType: 'Category', title: xAxisName, edgeLabelPlacement: 'Shift', titleStyle: TitleStyle, labelStyle: LabelStyle };
      primaryyAxis = { minimum: 0, title: yAxisName, titleStyle: TitleStyle, labelStyle: LabelStyle };
      return (
        <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
          <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Category]} />
          <SeriesCollectionDirective>
            <SeriesDirective dataSource={data} xName='x' yName='y' type='Bar' dataLabelTemplate={<i class="fas fa-star"></i>} />
          </SeriesCollectionDirective>

        </ChartComponent>
      )
    case "area":

      primaryxAxis = {
        valueType: 'Category', title: xAxisName, titleStyle: TitleStyle, labelStyle: LabelStyle
      };
      primaryyAxis = { minimum: 0, title: yAxisName, titleStyle: TitleStyle, labelStyle: LabelStyle };
      const marker = { visible: true, width: 10, height: 10, border: { width: 2, color: '#F8AB1D' }, dataLabel: { visible: true, position: 'Top' } };
      return (
        <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
          <Inject services={[AreaSeries, Legend, Tooltip, DataLabel, Category]} />
          <SeriesCollectionDirective>
            <SeriesDirective dataSource={data} xName='x' yName='y' type='Area' fill="#8884d8" marker={marker} />
          </SeriesCollectionDirective>
        </ChartComponent>
      );

    case "pie":
      const ItemWithValue = data.find(c => c.y > 0);
      //console.log(ItemWithValue);
      if (!ItemWithValue)
        data = [{ x: "No Items", y: 1, color: "#111" }];

      return (
        <ResponsiveContainer width={"100%"} height={600}>
          <PieChart>
            <Pie data={data} cx={"%50"} cy={"50%"} dataKey={"y"}
              label={props => renderCustomizedLabel(props, !ItemWithValue)}
              labelLine={true} stroke='#dfdfdf' nameKey={"x"} >
              {data.map((e, idx) => (
                <Cell key={`cell-${idx}`} fill={e.color ? e.color : GetColor(idx)} stroke='#000' />
              ))}
            </Pie>
            <PieLegend content={renderLegend(data)} align="center" />
          </PieChart>
        </ResponsiveContainer>
      )
  }
}


const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

const getLegendColour = (colour, index) => {
  if (colour)
    return colour;
  else
    return GetColor(index);
}

const renderLegend = (data) => {
  //console.log(data)
  return (
    <div className="justify-content-center">
      <div className='d-flex justify-content-center' style={{ flexWrap: "wrap" }}>
        {data.map((legend, index) =>
          index <= data.length &&
          <div key={index} className="mr-4 d-flex align-items-center px-1">
            <div className="inline-block rounded-full items-center justify-center m-1" style={{ backgroundColor: getLegendColour(legend.color, index), width: 15, height: 15 }}></div>
            <div className="inline-block">{capitalizeFirstLetter(legend.x)}</div>
          </div>)}
      </div>
    </div>
  );
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props, hideValues) => {
  //console.log(props);
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, value, fill } = props;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    (value > 0 && !hideValues) ?
      <text x={x} y={y} fill={"#000"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(1)}% (${value})`}
      </text>
      : <></>
  );
};

